import '../styles/index.scss';

document.addEventListener('DOMContentLoaded', () => {
	const range = document.querySelector('.range')
	const slider = document.querySelector('.slider')

	range.addEventListener('input', e => {
		const value = e.target.value
		slider.style.transform = `translateY(-30%) translateX(calc(100vw / 7 * -${value - 1}))`

		let activeIndexes = document.querySelectorAll('[data-index].active')

		activeIndexes.forEach(index => {
			index.classList.remove('active')
		})

		let toActive = document.querySelectorAll(`[data-index="${value}"]`)

		toActive.forEach(index => {
			index.classList.add('active')
		})

		let activeColor = document.querySelector(`.slide[data-index="${value}"]`).dataset.color
		console.log(activeColor);
		document.querySelector('.top-bottle').style.backgroundColor = activeColor
	})
})